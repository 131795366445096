import React, { useState } from "react"
import NewLayout from "../components/new_layout"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import LinkButton from "../components/v2023/UI/LinkButton/link-button"
import { Player } from "@lottiefiles/react-lottie-player"
import { Modal } from "antd"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"
import Channels from "../components/v2023/CustomerHub/channels"

const CustomerHub = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page

    const [stepIndexPulse, setStepIndexPulse] = useState(0)
    const [stepSelected, setStepSelected] = useState(page.animation_steps[0])
    const [showStepModal, setShowStepModal] = useState(false)

    const openStepModal = (index) => {
        setStepSelected(page.animation_steps[index])
        setShowStepModal(true)
        setStepIndexPulse(index !== (page.animation_steps.length - 1) ? index + 1 : index)
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={lang}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />

            <main className="main">
                <div className="main__section  main__section--100 color-section purple-dark frame-gray">
                    <div className="container">
                        <div className="tag tag--highlight" data-aos="fade-up">{page.tag}</div>
                        <div className="container__text" data-aos="fade-up">
                            <h1 className="merriweather">{page.title}</h1>
                            <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
                            <div className="button__wrapper">
                                <LinkButton button={page.request_demo} className="button button--ghost button--xl" />
                            </div>
                        </div>
                    </div>

                    <div className="container container--np" data-aos="fade-up">
                        <div className="browser-image">
                            <div className="hidden-sm">
                                <Player src={page.animation.localFile.publicURL} autoplay={true} loop={true} />
                            </div>
                            <div className="show-sm">
                                <Player src={page.animation_mobile.localFile.publicURL} autoplay={true} loop={true} />
                            </div>

                            <div className="browser-image__buttons browser-image__buttons--customer-hub">
                                {page.animation_steps.map((item, index) => {
                                    return <button
                                        key={index}
                                        onClick={() => openStepModal(index)}
                                        className={`button-icon button-icon--number ${index === stepIndexPulse ? "button-icon--number--pulse" : ""}`}>
                                        {item.order}
                                    </button>
                                })}

                                <Modal
                                    centered
                                    onCancel={() => setShowStepModal(false)}
                                    visible={showStepModal}
                                    title={<><h4>{stepSelected.title}</h4><ReactMarkdown children={stepSelected.description} rehypePlugins={[rehypeRaw]} /></>}
                                    width={"100%"}
                                    footer={null}
                                    className="ant-modal--green"

                                >
                                    <GatsbyImage alt={stepSelected.icon.alternativeText} image={getImage(stepSelected.icon.localFile)} />
                                </Modal>
                            </div>
                        </div>
                    </div>

                    <div className="container container--center" data-aos="fade-up">
                        <div className="grid-lg-3-h">
                            {page.characteristics.map((item, index) => {
                                const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index }
                                return <div className="image-group" key={index} {...animation}>
                                    <div>
                                        <GatsbyImage alt={item.image.alternativeText} image={getImage(item.image.localFile)} />
                                    </div>
                                    <div className="image-group__text">
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100 color-section gray gray--nograph">
                    <div className="container container--slim">
                        <div className="tile tile--white--opinion">
                            <div className="tile__body">
                                <div className="tile__body__column">
                                    <div className="column__image">
                                        <img src={page.opinion.company_logo_2.localFile.publicURL} alt={page.opinion.company_logo_2.alternativeText} />
                                    </div>
                                </div>
                                <div className="tile__body__column">
                                    {page.opinion.message}
                                    <div className="avatar">
                                        <div className="avatar__job">
                                            <b>{page.opinion.name}</b>
                                            <span>{page.opinion.job_position}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-lg-2">
                            <div className="tile tile--white--soft">
                                <div className="tile__header">
                                    <h4>{page.info_others.title}</h4>
                                </div>
                                <div className="tile__body">
                                    <ReactMarkdown children={page.info_others.description} rehypePlugins={[rehypeRaw]} />
                                </div>
                            </div>
                            <div className="tile tile--white--nb">
                                <div className="tile__header">
                                    <h4>{page.info_tecalis.title}</h4>
                                    <div className="tag tag--status"><i className="icon-rocket"></i>{page.info_tecalis.tag}</div>
                                </div>
                                <div className="tile__body">
                                    <ReactMarkdown children={page.info_tecalis.description} rehypePlugins={[rehypeRaw]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Channels
                    channel_inperson_callcenter={page.channel_inperson_callcenter}
                    channel_online={page.channel_online}
                    channel_all={page.channel_all}
                />

                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tag tag--status"><i className="icon-rocket"></i>{page.ad_hoc_tag}</div>
                        <h2>{page.ad_hoc_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.ad_hoc_description} rehypePlugins={[rehypeRaw]} />
                        </div>

                        <div className="grid-lg-4">
                            {page.ad_hoc_items.map((item, index) => (
                                <div className="image-group" key={index}>
                                    <div>
                                        <GatsbyImage alt={item.image.alternativeText} image={getImage(item.image.localFile)} />
                                    </div>
                                    <div className="image-group__text">
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="grid-sm-4">
                            {page.metrics.map((item, index) => {
                                const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index }
                                return <div className="stat" key={index} {...animation}>
                                    <div className="title-xl">{item.number}{item.symbol}</div>
                                    <span><ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} /></span>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tile tile--image">
                            <div className="tile__body">
                                <h3>{page.add_ons.name}</h3>
                                <ReactMarkdown children={page.add_ons.description} rehypePlugins={[rehypeRaw]} />
                                <Link to={page.add_ons.url} className="button button--small">{page.add_ons.button} <i className="icon-arrow-right"></i></Link>
                            </div>
                            <div className="tile__image">
                                <GatsbyImage alt={page.add_ons.image.alternativeText} image={getImage(page.add_ons.image.localFile)} />
                            </div>
                        </div>
                    </div>
                </div>

                <ReadyToTalk lang={lang} />
            </main>

        </NewLayout>
    )
}

export default CustomerHub

export const customerHubQuery = graphql`
    query($langKey: String) {
        page: strapi2023CustomerHub ( locale: { eq: $langKey } ) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL
                    }
                }
            }
            tag
            title
            description
            request_demo {
                text
                url
                blank
            }
            animation {
                localFile {
                    publicURL
                }
            }
            animation_mobile {
                localFile {
                    publicURL
                }
            }
            animation_steps {
                order
                title
                description
                icon {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 662
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
                image_mobile {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 664
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            characteristics {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 64
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            opinion {
                name
                job_position
                message
                company_logo_2 {
                    name
                    alternativeText
                    localFile {
                        publicURL
                    }
                }
                photo {
                    name
                    alternativeText
                    localFile {
                        publicURL
                    }
                }
            }
            info_others {
                title
                description
            }
            info_tecalis {
                title
                tag
                description
            }
            channel_inperson_callcenter {
                tags {
                    name
                    type
                }
                title
                description
                button {
                    text
                    url
                    blank
                }
                video {
                    video_url
                    video_preview {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 1280
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
            channel_online {
                tags {
                    name
                    type
                }
                title
                description
                button {
                    text
                    url
                    blank
                }
                video {
                    video_url
                    video_preview {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 1280
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
            channel_all {
                tags {
                    name
                    type
                }
                title
                description
                button {
                    text
                    url
                    blank
                }
                video {
                    video_url
                    video_preview {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 1280
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
            ad_hoc_tag
            ad_hoc_title
            ad_hoc_description
            ad_hoc_items {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 480
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            metrics {
                number
                symbol
                description
            }
            add_ons {
                name
                description
                url
                button
                blank
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 464
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
        }
    }
`
